/**
 * Created by jerry on 2020/03/23.
 * 套餐卡api
 */
import * as API from '@/api/index'

export default {
    // 套餐卡列表
    packageCardList: params => {
        return API.POST('api/packageCard/list',params)
    },
    // 套餐卡销售范围
    packageCardSaleEntity: params => {
        return API.POST('api/packageCard/packageCardSaleEntity',params)
    },
    // 新增
    create: params => {
        return API.POST('api/packageCard/create',params)
    },
    // 新增
    update: params => {
        return API.POST('api/packageCard/update',params)
    },
    //获取套餐卡明细
    packageCardGoods: params => {
        return API.POST('api/packageCard/packageCardGoods',params)
    },
    //获取套餐卡明细
    getPackageCardDetails: params => {
      return API.POST('api/packageCard/getPackageCardDetails',params)
    },
}
